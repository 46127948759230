/* eslint-disable global-require */
/* eslint-disable max-len */
/* eslint-disable no-irregular-whitespace */
import PlainContent from '@/types/PlainContent';
import { JobSector, JobSectorType } from '@/types/JobSectors';

export const JobSectors: {
  [sector in JobSectorType]: JobSector
} = {
  'site-operations': {
    intro: {
      title: 'Explore Site Operations',
      body: `<p>
            Our Site Operations is home to a variety of diverse job families. 
          </p>
          <p>
            Each family includes a wide range of roles and opportunities for all skill levels. 
          </p>
          <p>
            <strong>
              You can learn more about each family below. 
            </strong>
          </p>`,
    },
    families: [
      {
        img: require('@/assets/images/job-sectors/site-operations/catering-hospitality-narrative-desktop.png'),
        imgMobile: require('@/assets/images/job-sectors/site-operations/catering-hospitality-narrative-mobile.png'),
        title: 'Catering & Hospitality',
        body: `<p>
                There’s so much more to catering than just the tasty food we provide; our Catering job family are friendly, people-focused and work in a variety of kitchens, from training campuses to Hinkley Point C itself.
              </p>
              <p>
                Joining the Catering job family provides people of all abilities and experiences with the opportunity to grow in a progressive and diverse environment that will continue to thrive once the power plant is operational.
              </p>
              <p> 
                If feeding the workforce doesn’t spark your interest, we have plenty of other ways you can get involved. Our fast-paced kitchens are not complete without those who keep the tills running, the eating areas hygienic, and the health and safety regulations in place.
              </p>
              <p>
                Our Catering job family would be a welcome home to those who love the idea of keeping busy in a dynamic work environment as part of a large, collaborative team.
              </p>`,
      },
      {
        img: require('@/assets/images/job-sectors/site-operations/facilities-management-narrative-desktop.png'),
        imgMobile: require('@/assets/images/job-sectors/site-operations/facilities-management-narrative-mobile.png'),
        title: 'Facilities Management',
        body: `<p>
                Members of our Facilities Management family are the gears that keep our facilities clean and safe. Hinkley Point C has many areas that require the dedication of our Facilities Management, including accommodation sites, coffee shops, health centres, warehouses, and offices.
              </p>
              <p>
              You’ll be using your communication skills to collaborate with different teams and suppliers. As a member of the Facilities Management family, you’ll be key in coordinating the office and maintaining our high hygiene standards and you’ll be using your communication skills to collaborate with different teams and suppliers.
              </p>
              <p> 
              So, if you like keeping busy, enjoy working with others, and want to contribute towards the standards upheld at Hinkley Point C, the Facilities Management job family would love to welcome you.
              </p>`,
      },
      {
        img: require('@/assets/images/job-sectors/site-operations/medical-narrative-desktop.png'),
        imgMobile: require('@/assets/images/job-sectors/site-operations/medical-narrative-mobile.png'),
        title: 'Medical',
        body: `<p>
                With a lot of manual and physically-taxing work being carried out at Hinkley Point C, we want to make sure our employees are cared for in the best way possible.
              </p>
              <p>
                We have our own medical centre, Hinkley Health, which is equipped with GPs, nurses, a physiotherapist, and ambulances.
              </p>
              <p>
                We have opportunities for a variety of roles in the Emergency Services family. With your medical knowledge, communication, and interpersonal skills, we know you will make sure our employees are as well-oiled and maintained as our machines.
              </p>`,
      },
      {
        img: require('@/assets/images/job-sectors/site-operations/security-narrative-desktop.png'),
        imgMobile: require('@/assets/images/job-sectors/site-operations/security-narrative-mobile.png'),
        title: 'Security',
        body: `<p>
          With such a large-scale site, our Security job family work hard to ensure that all staff and machinery remain safe day-in and day-out.
        </p>
        <p>
          You’ll be working to guard both campuses, Park and Rides, offices, and the main entrance. As they say, with great power, comes great responsibility and you’ll play a vital role in keeping the site safe and secure.
        </p>
        <p>
          If the front-facing role of a security guard doesn’t spark your interest, but you have a knack for organisation and a passion for keeping others safe, you can get involved in our control rooms or incident reporting processes.
        </p>`,
      },
    ],
  },
  'civil-construction': {
    intro: {
      title: 'Explore Civil Construction',
      body: `<p>
            Our Civil Construction is home to a variety of diverse job families.
          </p>
          <p>
            Each family includes a wide range of roles and opportunities for all skill levels.
          </p>
          <p>
            <strong>
              You can learn more about each family below.
            </strong>
          </p>`,
    },
    families: [
      {
        img: require('@/assets/images/job-sectors/civil-construction/construction-plant-maintenance-narrative-desktop.png'),
        imgMobile: require('@/assets/images/job-sectors/civil-construction/construction-plant-maintenance-narrative-mobile.png'),
        title: 'Construction Plant Maintenance',
        body: `<p>
              The plant at Hinkley Point C work just as many hours as the workforce and maintaining these machines is crucial to the delivery of our project.
            </p>
            <p>
              Our Plant Maintenance job family is perfect for those with a passion for problem-solving and working with their hands; you’ll be part of a collaborative group who are dedicated to keeping our plant in tip-top shape.
            </p>
            <p>
              The range of plant on site means that your weeks will rarely be the same and you’ll have plenty of opportunities to learn and develop your skills on a variety of different machines, including rollers, excavators, lifting equipment, and more!
            </p>`,
      },
      {
        img: require('@/assets/images/job-sectors/civil-construction/construction-management-narrative-desktop.png'),
        imgMobile: require('@/assets/images/job-sectors/civil-construction/construction-management-narrative-mobile.png'),
        title: 'Construction Management',
        body: `<p>
              From Construction Leads and Quantity Surveyors, all the way through to Clerk of Works and Construction Engineers, those within our Construction Management family are directly involved in the construction of Hinkley Point C.
            </p>
            <p>
              Key to the delivery of construction projects, the Construction Management family consists of organised, collaborative, and knowledgeable leaders. You’ll be using your strong communication skills and keen eye for detail to ensure that your teams are working smoothly and safely.
            <p>
              With a variety of different roles and leadership pathways available, this family is perfect for those who want to coordinate, oversee, and manage construction teams.
            </p>`,
      },
      {
        img: require('@/assets/images/job-sectors/civil-construction/lifting-operations-narrative-desktop.png'),
        imgMobile: require('@/assets/images/job-sectors/civil-construction/lifting-operations-narrative-mobile.png'),
        title: 'Lifting Operations',
        body: `<p>
              Perfect for those with a great attention to detail and strong focus, the Lifting Operations family is home to teams who manage the lifting, lowering, and assembly of loads in the power station. We’re providing an exciting opportunity to get involved with some unique equipment – Hinkley Point C is proud to host Big Carl; the world’s largest land crane.
            </p>
            <p>
              The scale of our project means that you’ll be working across numerous levels and responsible for safely getting materials to other teams on site. At peak construction, we have 52 tower cranes, 25 crawler cranes and a further 40 mobile cranes. That’s over 100 different cranes working in conjunction with one another.
            <p>
              Whether you’re keen to get stuck in with pulleys, winches, and shackles, or you’re better suited to calculating and coordinating, we’d be excited to welcome you into the Lifting Operations job family at Hinkley Point C.
            </p>`,
      },
      {
        img: require('@/assets/images/job-sectors/civil-construction/mechanical-and-electrical-narrative-desktop.png'),
        imgMobile: require('@/assets/images/job-sectors/civil-construction/mechanical-and-electrical-narrative-mobile.png'),
        title: 'Mechanical & Electrical',
        body: `<p>
              Our Mechanical and Electrical job family is dedicated to the installation, commission, and maintenance of a variety of equipment and controls.
            </p>
            <p>
              From gas fitting through to heating and ventilation, this family is perfect for those who like to get stuck in and use their logical mindset in a fast-paced, dynamic environment. The scale of Hinkley Point C means that we can offer you a diverse and unique setting to learn, adapt, and develop your skills.
            <p>
              With plenty of progressive pathways and opportunities available, the Mechanical and Electrical job family caters to a variety of experience levels and interests.
            </p>`,
      },
      {
        img: require('@/assets/images/job-sectors/civil-construction/plant-operations-narrative-desktop.png'),
        imgMobile: require('@/assets/images/job-sectors/civil-construction/plant-operations-narrative-mobile.png'),
        title: 'Plant Operations',
        body: `<p>
              From excavators, to rollers, to forklifts, to dump trucks, our Plant Operations job family is home to skilled operators who drive a vast array of heavy machinery.
            </p>
            <p>
              Whether you want to get involved in heavy, bulking work, or want to get stuck into more intricate work, we will have something for you to get your (or your excavator’s) teeth into.
            <p>
              Our Plant Operators work collaboratively with our teams on the ground to provide support and ensure that work is carried out quickly and safely.
            </p>
            <p>
              If you have a vision of operating site machinery, you’ll fit right in as a member of our Plant Operations job family.
            </p>
            <p>
              While we have now passed this stage of the project, we are anticipating roles to become available on our system project SZC. You can register your interest on our Job Service to be one of the first to hear about opportunities.
            </p>`,
      },
      {
        img: require('@/assets/images/job-sectors/civil-construction/utilities-narrative-desktop.png'),
        imgMobile: require('@/assets/images/job-sectors/civil-construction/utilities-narrative-mobile.png'),
        title: 'Utilities',
        body: `<p>
              From water and gas to fibre optics, as a member of the Utilities job family, you’ll be key in bringing together the Hinkley Point C circulatory system.
            </p>
            <p>
              We are looking for people to install and maintain the utilities that hold the body of Hinkley Point C together. We offer roles that involve working with a variety of materials. Members of our Utilities job family are great with their hands, love getting stuck in, and have great attention to detail.
            <p>
              If you are looking for an opportunity that will offer you a unique, fast-paced, and dynamic workday, or you have an interest in the 'lifeblood' that is utilities, we are the family for you.
            </p>
            `,
      },
    ],
  },
  'mechanical-electrical': {
    intro: {
      title: 'Explore Mechanical & Electrical (M&E)',
      body: `<p>
            Our Mechanical &amp; Electrical (M&E) is home to a variety of diverse job families. 
          </p>
          <p>
            Each family includes a wide range of roles and opportunities for all skill levels. 
          </p>
          <p>
            <strong>
              You can learn more about each family below.
            </strong>
          </p>`,
    },
    families: [
      {
        img: require('@/assets/images/job-sectors/mechanical-electrical/scaffolding-narrative-desktop.png'),
        imgMobile: require('@/assets/images/job-sectors/mechanical-electrical/scaffolding-narrative-mobile.png'),
        title: 'Scaffolding',
        body: `<p>
              The Scaffolding job family are vital to the construction of Hinkley Point C; many of our colleagues will require access via scaffolds, guard rails, and planks.
            </p>
            <p>
              No two days are the same. You will be working across varied environments and will have the opportunity to work with others as part of a close-knit team.
            <p>
              As a member of the Scaffolding job family, you’ll spend your day enjoying the fresh air, fixing scaffolding to structures, and ensuring that everyone has safe access to areas across Hinkley Point C.
            </p>
            `,
      },
      {
        img: require('@/assets/images/job-sectors/mechanical-electrical/industrial-painters-narrative-desktop.png'),
        imgMobile: require('@/assets/images/job-sectors/mechanical-electrical/industrial-painters-narrative-mobile.png'),
        title: 'Industrial Painters',
        body: `<p>
              Hinkley Point C will continue to run long after the construction has been completed; this means that the structure needs to be long-lasting and robust.
            </p>
            <p>
              Our job family will be responsible for ensuring that our structures are protected for years to come. Our teams apply specialist coating to a variety of materials, so you’ll have the opportunity to develop your skills in a range of different environments.
            <p>
              If working as part of a small team, and ensuring that our structures are safe and protected, is what you're looking for, our job family would love to have you.
            </p>
            `,
      },
      {
        img: require('@/assets/images/job-sectors/mechanical-electrical/construction-site-operations-narrative-desktop.png'),
        imgMobile: require('@/assets/images/job-sectors/mechanical-electrical/construction-site-operations-narrative-mobile.png'),
        title: 'Construction Site Operations',
        body: `<p>
              This ambitious project couldn’t run without the skills and contributions of those within the Construction Site Operations job family.
            </p>
            <p>
              This diverse job family offers opportunities and pathways for a variety of different trades and crafts; you’ll be spoilt for choice, regardless of your experience level.
            </p>
            <p>
              Whether you’re laying concrete, getting involved with carpentry, or fixing steel, you’ll play a key role in the delivery of the Hinkley Point C Project.
            </p>
            <p>
              This family is perfect for those who like to keep active, busy, and have an interest in a more physical role. The outdoor nature of this job family means you’ll also get the added benefit of spending each day taking in the lovely Somerset air.
            </p>
            <p>
              The construction of Hinkley Point C is a unique and vitally important project so where better to develop your skills than as a member of our Construction Site Operations family?
            </p>
            `,
      },
      {
        img: require('@/assets/images/job-sectors/mechanical-electrical/electrical-narrative-desktop.png'),
        imgMobile: require('@/assets/images/job-sectors/mechanical-electrical/electrical-narrative-mobile.png'),
        title: 'Electrical',
        body: `<p>
              We have an exciting range of technologies and structures at Hinkley Point C and our Electrical job family are vital to ensuring these are built and maintained correctly.
            </p>
            <p>
              Members of our Electrical job family have varying levels of expertise and experience, from Cable Installers to Containment Installers, to Electricians to Electrical Supervisors and Cable Jointers. We have a range of opportunities that can cater to those who love a more physical role both indoors and outdoors and those who can work on smaller, methodical jobs.
            </p>
            <p>
              The unique nature of the Hinkley Point C project means that there is no better place to learn, develop, or master your skills.
            </p>
            `,
      },
      {
        img: require('@/assets/images/job-sectors/mechanical-electrical/engineering-narrative-desktop.png'),
        imgMobile: require('@/assets/images/job-sectors/mechanical-electrical/engineering-narrative-mobile.png'),
        title: 'Engineering',
        body: `<p>
              Engineering includes so much more than creating plans; we believe that Engineering is pivotal to solving the most impactful problems within our world, including the revitalisation of our nuclear power industry.
            </p>
            <p>
              Our Engineering job family covers a variety of roles, ranging from Design/CAD Technician to Lab Technician, to Methods Engineer.
            </p>
            <p>
              As a member of our Engineering family, you’ll play a vital role in planning, designing, and overseeing the construction and maintenance of structures within Hinkley Point C. You’ll be collaborating with a number of different teams and using your strong, logical mindset to help deliver the Hinkley Point C vision.
            </p>
            <p>
              If you’d like to see your plans come to life, work in one of our labs, or get involved with our digital engineering designs, join us as a member of our Engineering family.
            </p>
            `,
      },
      {
        img: require('@/assets/images/job-sectors/mechanical-electrical/erecting-narrative-desktop.png'),
        imgMobile: require('@/assets/images/job-sectors/mechanical-electrical/erecting-narrative-mobile.png'),
        title: 'Erecting (M&E)',
        body: `<p>
                Working closely with our teams of architects and design engineers, members of our M&E Erecting job family are dedicated to the careful construction of a range of structures. During our project, the amount of steel that we’ll be using is the equivalent to 32 Eiffel Towers!
              </p>
              <p>
                No two days are the same in the M&E Erecting family and you’ll find yourself undertaking dynamic and exciting tasks. You’ll be working at height, using mobile cranes, and dedicated to ensuring that all tasks are carried out safely.
              </p>
              <p>
                Teams within this job family are often small, so you’ll get to work as part of a close-knit group and develop your skills on a vast variety of different structures. Where better to broaden your experience than on a unique project like Hinkley Point C?
            </p>
            `,
      },
      {
        img: require('@/assets/images/job-sectors/mechanical-electrical/insulation-narrative-desktop.png'),
        imgMobile: require('@/assets/images/job-sectors/mechanical-electrical/insulation-narrative-mobile.png'),
        title: 'Insulation',
        body: `<p>
              Hinkley Point C is taking insulation to new levels and scopes; the role of insulation is crucial to keep the plant working at the correct temperature when we’re operational.
            </p>
            <p>
              You’ll be key in the implementation of insulation at Hinkley Point C and working with a wide range of materials and systems. Whether you’re already established in the industry, or if you’re looking to learn, this project will provide you with a wealth of experience and opportunities to develop your skills.
            </p>
            <p>
              From cladding, to pipes, to vessels, to large mechanical systems, you’ll get stuck in on a range of different activities.
            </p>
            `,
      },
      {
        img: require('@/assets/images/job-sectors/mechanical-electrical/mechanical-narrative-desktop.png'),
        imgMobile: require('@/assets/images/job-sectors/mechanical-electrical/mechanical-narrative-mobile.png'),
        title: 'Mechanical',
        body: `<p>
                Members of our Mechanical job family are key for the successful fitting and operation of our mechanical equipment.
              </p>
              <p>
                From Pipe Fitters, to Fabricators, through to Mechanical Supervisors, we offer a wide range of opportunity for those with an interest in mechanics.
              </p>
              <p>
                We’re proud to provide a progressive and unique environment for those looking to work in the mechanical industry and welcome anyone with a logistic mindset, attention to detail, and a passion for working safely.
              </p>
              <p>
                If that sounds like you, the Mechanical job family would love to welcome you.
            </p>
            `,
      },
      {
        img: require('@/assets/images/job-sectors/mechanical-electrical/ndt-narrative-desktop.png'),
        imgMobile: require('@/assets/images/job-sectors/mechanical-electrical/ndt-narrative-mobile.png'),
        title: 'NDT',
        body: `<p>
              We’re committed to ensuring that everything we do adheres to our high standards of quality and safety. Our NDT (Non-destructive Testing) job family are key to ensuring we continually achieve this commitment.
            </p>
            <p>
              Members of our NDT family will inspect a variety of materials, structures, and technologies to detect any problems or defects. Their keen attention to detail, strong research skills, and ability to create thorough reports means that we can take pride in the quality of our work.
            </p>
            <p>
              If using specialist equipment, working with a range of different and unique materials, and collaborating with others sounds like it may interest you, you’ll fit right in with our NDT job family.
            </p>
            `,
      },
      {
        img: require('@/assets/images/job-sectors/mechanical-electrical/project-management-narrative-desktop.png'),
        imgMobile: require('@/assets/images/job-sectors/mechanical-electrical/project-management-narrative-mobile.png'),
        title: 'Project Management',
        body: `<p>
              Wherever you feel more at home, whether it be on site or behind a desk, Hinkley Point C has a range of Project Management opportunities.
            </p>
            <p>
              Our Project Management job family is home to teams of organised people who have an eye for detail and a talent for collaborating with others. The fast-paced, dynamic nature of this job family promises a challenging, yet rewarding, workday, and the opportunity to work with a wide range of groups.
            </p>
            <p>
              You’ll play a key role in the planning, budgeting, and delivery of large-scale projects at Hinkley Point C and use your strong communication skills to collaborate with contractors and team members.
            </p>
            <p>
              If you’re just starting out, or you’re well-versed in the Project Management world, we would love to welcome you into the Project Management job family.
            </p>
            `,
      },
      {
        img: require('@/assets/images/job-sectors/mechanical-electrical/quality-narrative-desktop.png'),
        imgMobile: require('@/assets/images/job-sectors/mechanical-electrical/quality-narrative-mobile.png'),
        title: 'Quality',
        body: `<p>
              Everyone at Hinkley Point C is committed to doing the job right first time, but those within our Quality job family have a particular passion for ensuring our projects run smoothly and our teams are working efficiently.
            </p>
            <p>
              The Quality job family support a variety of different teams from across all our sites and are dedicated to maintaining our high standards. The dynamic nature of these roles means that no day is ever the same and you’ll be using your problem-solving skills to contribute towards continual improvements to the quality of our work.
            </p>
            <p>
              Where better to flex your analytical mindset than on a unique project like Hinkley Point C?
            </p>
            `,
      },
      {
        img: require('@/assets/images/job-sectors/mechanical-electrical/rigging-narrative-desktop.png'),
        imgMobile: require('@/assets/images/job-sectors/mechanical-electrical/rigging-narrative-mobile.png'),
        title: 'Rigging',
        body: `<p>
              While some may fear the idea of working from height, our Rigging job family thrive on the dynamic and exciting nature of the opportunities available at Hinkley Point C.
            </p>
            <p>
              We’re proud to offer a range of unique projects at Hinkley Point C for you to get involved with.
            </p>
            <p>
              We’re passionate about safety, so you’ll have a keen attention to detail and be able to collaboratively work with our teams to ensure our high standards are maintained. You’ll also be able to get stuck in with the unique equipment we have on site.
            </p>
            <p>
              So, if working, in the open air and as part of a close-knit team sounds like something you’d find interesting, the Rigging job family would love to welcome you.
            </p>
            `,
      },
      {
        img: require('@/assets/images/job-sectors/mechanical-electrical/welding-narrative-desktop.png'),
        imgMobile: require('@/assets/images/job-sectors/mechanical-electrical/welding-narrative-mobile.png'),
        title: 'Welding',
        body: `<p>
              At Hinkley Point C we have those who look at the bigger picture and those who look at the much smaller, intricate part of the picture. As someone with a good attention for detail and a passion for high quality craftmanship, you may find yourself at home within our Welding job family.
            </p>
            <p>
              No matter your prior experience, we offer a pathway from Trainee to Advanced Welder so there is always opportunity for progression. Where better to hone, develop, or learn new skills than the unique and revolutionary environment at Hinkley Point C?
            </p>
            `,
      },
    ],
  },
  'support-roles': {
    intro: {
      title: 'Explore Support Roles',
      body: `<p>
            Our Support Roles is home to a variety of diverse job families.
          </p>
          <p>
            Each family includes a wide range of roles and opportunities for all skill levels.
          </p>
          <p>
            <strong>
              You can learn more about each family below.
            </strong>
          </p>`,
    },
    families: [
      {
        img: require('@/assets/images/job-sectors/support-roles/administration-narrative-desktop.png'),
        imgMobile: require('@/assets/images/job-sectors/support-roles/administration-narrative-mobile.png'),
        title: 'Administration',
        body: `<p>
                Our offices wouldn’t be able to operate without the dedication of our Administration job family. From Project Support to Personal Assistants, this job family is the administrative backbone of the project.
              </p>
              <p>
                Organisational skills, inter-personal skills, and confidence in communication skills are some of the characteristics that would fit in well with our administration team.
              </p>
              <p>
                If you’d like to apply your skills to support our teams during this unique project, the Administration job family would love to welcome you.
            </p>
            <p>
              No matter your prior experience, we offer a pathway from Trainee to Advanced Welder so there is always opportunity for progression. Where better to hone, develop, or learn new skills than the unique and revolutionary environment at Hinkley Point C?
            </p>
            `,
      },
      {
        img: require('@/assets/images/job-sectors/support-roles/finance-narrative-desktop.png'),
        imgMobile: require('@/assets/images/job-sectors/support-roles/finance-narrative-mobile.png'),
        title: 'Finance',
        body: `<p>
              Our Finance job family is about so much more than just crunching numbers; our teams are responsible for the financial success of projects. The unique nature of the Hinkley Point C project means that there are a wide variety of opportunities available, from accounting and risk analysis, to estimating and contracting.
            </p>
            <p>
            If, like our teams, you love a spreadsheet, have a passion for numbers,​ and want to contribute towards the success of our projects by ensuring top quality work within budgets, you’ll fit right in.
            </p>
            `,
      },
      {
        img: require('@/assets/images/job-sectors/support-roles/health-and-safety-narrative-desktop.png'),
        imgMobile: require('@/assets/images/job-sectors/support-roles/health-and-safety-narrative-mobile.png'),
        title: 'Health & Safety',
        body: `<p>
              Those within the Health and Safety job family are dedicated to supporting our teams and ensuring everyone is working safely.
            </p>
            <p>
              The roles within this family are diverse: from being a member of our dedicated fire team, to implementing our Health and Safety procedures. Each role is crucial to maintaining our high quality of health and safety at Hinkley Point C.
            </p>
            <p>
              Our colleagues rely on members of the Health and Safety family for advice, support, and knowledge when operating in our wide range of working environments.
            </p>
            <p>
              It’s a fast-paced and challenging family to be a part of, but that’s what makes it so exciting. You can come to work each day knowing that you’re making a positive difference to the construction of a vital piece of low carbon infrastructure.
            </p>
            `,
      },
      {
        img: require('@/assets/images/job-sectors/support-roles/human-resources-narrative-desktop.png'),
        imgMobile: require('@/assets/images/job-sectors/support-roles/human-resources-narrative-mobile.png'),
        title: 'Human Resources',
        body: `<p>
                Our front-line families wouldn’t be able to operate without the dedication of our Human Resources job family. From supporting personal development, to recruitment, our team are always on hand to provide guidance and encouragement.
              </p>
              <p>
                Members of the Human Resources job family are great with people, genuinely invested in our colleagues’ professional development, and are on hand for new starters to ensure their onboarding is as smooth as possible.
              </p>
              <p>
                If you’ve got a passion for supporting others, are keen to be on hand to provide advice, and want to help us create diverse, cohesive, and enthusiastic teams, the Human Resources job family will be perfect for you.
            </p>
            `,
      },
      {
        img: require('@/assets/images/job-sectors/support-roles/IT-narrative-desktop.png'),
        imgMobile: require('@/assets/images/job-sectors/support-roles/IT-narrative-mobile.png'),
        title: 'IT',
        body: `<p>
              Our sites wouldn’t be the same without the presence of our IT job family. They work closely with our teams to understand challenges, provide solutions, and offer support.
            </p>
            <p>
              Your ability to solve problems, understand technology, and work collaboratively with a wide range of people means that you’ll help our teams, and our projects, succeed.
            </p>
            <p>
              If you enjoy working in a dynamic environment and utilising your expertise to help your colleagues, the IT job family would be the perfect home for you.
            </p>
            `,
      },
      {
        img: require('@/assets/images/job-sectors/support-roles/driving-logistics-narrative-desktop.png'),
        imgMobile: require('@/assets/images/job-sectors/support-roles/driving-logistics-narrative-mobile.png'),
        title: 'Driving & Logistics',
        body: `<p>
              If being behind the wheel is your passion, we’d love to have you as a member of our Driving job family.
            </p>
            <p>
              With a variety of courses available for those with, or without, qualifications, this job family is perfect for those who feel at home on the road. From buses, to vans, to HGVs, there are options for everyone, including those with a flair for customer service.
            </p>
            <p>
              There are also logistical roles and progression opportunities available for those who have an interest in transport but prefer their wheels to be on their office chair!
            </p>
            `,
      },
    ],
  },
};

export const TakeQuestionnaireContent: PlainContent = {
  title: 'Take our Questionnaire',
  body: `<p>
          Now you've got an insight into the exciting opportunities we have on offer, let us guide you in the right direction.
        </p>
        <p>
          We'll ask you a few simple questions about your working preferences and match you with a job family that suits you.
        </p>`,
  buttonText: 'Get started',
  buttonUrl: '/questionnaire',
};
